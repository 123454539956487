import gql from 'graphql-tag';
import * as Types from 'types/schema';
import * as Urql from 'utils/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPublicCertificareQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.PublicCertificateFilters>;
}>;

export type GetPublicCertificareQuery = {
  __typename?: 'Query';
  publicCertificate?: {
    __typename?: 'PublicCertificateType';
    issuedOn?: string | null;
    issuedCertificate: {
      __typename?: 'CertificateType';
      id: string;
      html: string;
      image?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
      pdf?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
      dimensions: { __typename?: 'CertificateDimensions'; height: number; width: number };
    };
    user?: { __typename?: 'UserProfile'; name?: string | null } | null;
    content?: { __typename?: 'PublicContentType'; id: string; name: string } | null;
  } | null;
};

export const GetPublicCertificareDocument = gql`
  query getPublicCertificare($filters: PublicCertificateFilters) {
    publicCertificate(filters: $filters) {
      issuedOn
      issuedCertificate {
        id
        html
        image {
          url
        }
        pdf {
          url
        }
        dimensions {
          height
          width
        }
      }
      user {
        name
      }
      content {
        id
        name
      }
    }
  }
`;

export function useGetPublicCertificareQuery(
  options?: Omit<Urql.UseQueryArgs<GetPublicCertificareQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetPublicCertificareQuery, GetPublicCertificareQueryVariables>({
    query: GetPublicCertificareDocument,
    ...options,
  });
}
